* {
  outline: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a{
  cursor: pointer;
  -webkit-appearance: none;
}

section{
  position: relative;
  display: block;
  width: 100%;
  padding: 0 15px;
  box-sizing: border-box;
}

*.no-scroll {
  overflow: hidden;
}
